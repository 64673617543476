<template>
    <div class="p-3">
        <el-card shadow="never">
            <el-table :data="crops">
                <el-table-column width="90">
                    <template #default="scope">
                        <el-image :src="scope.row.iconUrl" fit="fill" style="width: 64px; height: 64px;" />
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="名称" sortable />
                <el-table-column label="操作" fixed="right" width="70">
                    <template #default="scope">
                        <el-button type="text" @click="showCropVarietiesDialog(scope.row)">品种</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <el-dialog :visible.sync="cropVarietiesDialogVisible" :title="crop.name+' 品种'" :close-on-click-modal="false"
            width="640px">
            <crop-varieties v-if="cropVarietiesDialogVisible" :crop-id="crop.id" />
        </el-dialog>
    </div>
</template>

<script>
    import CropVarieties from './components/CropVarieties.vue';

    export default {
        data() {
            return {
                crops: [],
                crop: {},
                cropVarietiesDialogVisible: false,
            };
        },
        components: { CropVarieties },
        methods: {
            async loadCrops() {
                let response = await this.$axios.get('/api/Crop/GetCrops');
                this.crops = response.data;
            },
            showCropVarietiesDialog(crop) {
                this.crop = JSON.parse(JSON.stringify(crop));
                this.cropVarietiesDialogVisible = true;
            },
        },
        created() {
            this.loadCrops();
        },
    };
</script>