<template>
    <div>
        <div class="row no-gutters">
            <div class="col">
                <el-button type="primary" @click="showCropVarietyDialog(null)">添加</el-button>
            </div>
        </div>
        <el-table :data="cropVarieties" class="mt-2">
            <el-table-column prop="name" label="名称" sortable />
            <el-table-column label="操作" fixed="right" width="100">
                <template #default="scope">
                    <el-button type="text" @click="showCropVarietyDialog(scope.row)">修改</el-button>
                    <el-button type="text" class="text-danger" @click="deleteCropVariety(scope.row.id)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :visible.sync="cropVarietyDialogVisible" title="作物品种" :close-on-click-modal="false" append-to-body
            width="400px">
            <el-form>
                <el-form-item label="名称" class="form-item-required">
                    <el-input v-model="cropVariety.name" placeholder="名称" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="saveCropVariety">确定</el-button>
                <el-button @click="cropVarietyDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            cropId: { type: String },
        },
        data() {
            return {
                cropVarieties: [],
                cropVariety: {},
                cropVarietyDialogVisible: false,
            };
        },
        methods: {
            async loadCropVarieties() {
                let response = await this.$axios.get('/api/Crop/GetCropVarieties', { params: { cropId: this.cropId } });
                this.cropVarieties = response.data;
            },
            showCropVarietyDialog(cropVariety) {
                if (cropVariety) {
                    this.cropVariety = JSON.parse(JSON.stringify(cropVariety));
                }
                else {
                    this.cropVariety = {};
                }
                this.cropVarietyDialogVisible = true;
            },
            async saveCropVariety() {
                if (this.cropVariety.id == undefined) {
                    await this.$axios.post('/api/Crop/AddCropVariety', {
                        cropId: this.cropId,
                        name: this.cropVariety.name,
                    });
                    this.cropVarietyDialogVisible = false;
                    this.loadCropVarieties();
                }
                else {
                    await this.$axios.post('/api/Crop/UpdateCropVariety', {
                        id: this.cropVariety.id,
                        name: this.cropVariety.name,
                    });
                    this.cropVarietyDialogVisible = false;
                    this.loadCropVarieties();
                }
            },
            async deleteCropVariety(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/Crop/DeleteCropVariety', { id: id });
                this.loadCropVarieties();
            },
        },
        created() {
            this.loadCropVarieties();
        },
    };
</script>